import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import PageLayout from '../components/PageLayout';
import SEO from '../components/seo';
import '../App.css';
require('typeface-inter');

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Point = styled.h2`
  font-weight: 500;
  font-size: 25px;
  margin-top: 0px;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const highlight = {
    background: '#b4f2e1',
    color: '#33334F',
};

const redHighlight = {
    background: '#ff8585',
    color: '#33334F',
};

const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState('70%');
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <PageLayout>
            <SEO
                title={'React Native Consultant'}
                description={'React Native expert to optimize performance by performance profiling'}
                image={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fcover.jpg?alt=media&token=be27bf77-5922-4e92-957a-b0a4afc7f22f'}
            />
            <Container style={{backgroundColor: '#f5f5fb'}}>
                <Banner windowWidth={windowWidth}/>
            </Container>

            <div style={{textAlign: 'center'}}>
                <Container>
                    <picture>
                        <img
                            style={{
                                width: '120px',
                                borderImageWidth: '2px',
                                borderColor: '#00000',
                                borderRadius: '50%'
                            }}
                            alt='React Native Consultant'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fharsh.png?alt=media&token=0ebf4601-f177-4127-959e-997b8b9531b1'}
                        />
                    </picture>
                </Container>
                <div style={{fontWeight: '600', fontSize: '25px', textAlign: 'center', lineHeight: '1.3'}}>
                    {`Hi, I am Harsh Vardhan. I'm a React Native focused consultant`}
                </div>
            </div>

            <br/>

            <div style={{marginTop: 30}}>
                <Container style={{margin: '0 auto', width: screenWidth, backgroundColor: '#f5f5fb'}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        Is your project facing these issues ?
                    </div>
                    <br/>
                    <h2 style={{fontSize: '25px', fontWeight: '400', lineHeight: '1.3', textAlign: 'left'}}>
                        <ul className='wrong'>
                            <li><Point>Too much platform specific (Swift/Kotlin) code</Point></li>
                            <li><Point>Slow pace of releasing to App store & Play store</Point></li>
                            <li><Point>Performance doesn't feels like a native app</Point></li>
                            <li><Point>Slow pace of shipping features</Point></li>
                        </ul>
                    </h2>
                </Container>
            </div>

            <div style={{marginTop: 30}}>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        {`How my consulting benefits your business ?`}
                    </div>
                    <h2 style={{fontSize: '25px', fontWeight: '400', lineHeight: '1.3', textAlign: 'left'}}>
                        <ul className='a'>
                            <li><Point>Ship features to customers faster</Point></li>
                            <li><Point>Reduce bugs in your product</Point></li>
                            <li><Point>Reduce development and maintenance cost</Point></li>
                        </ul>
                    </h2>
                </Container>
            </div>

            <div>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        A preview of my own product <a href="https://github.com/TrakBit/airplug-mobile"> Airplug.xyz</a> (client ones can't be disclosed)
                    </div>

                    <Container style={{textAlign: 'center'}}>
                        <picture>
                            <img
                                style={{
                                    width: '70%'
                                }}
                                alt='React Native Consultant'
                                src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fairtable-offline-mode.jpg?alt=media&token=bd1570d7-675d-436a-bfdd-60cf041774b5'}
                            />
                        </picture>
                    </Container>
                </Container>
            </div>

            <div style={{backgroundColor: '#f5f5fb'}}>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <button
                        style={{margin: '100px 0 100px 0'}}
                        onClick={() =>
                            window.location.href = 'mailto:harsh.vardhan.611@gmail.com?subject=React Native Consulting'
                        }
                    >
                        Email Harsh
                    </button>
                </Container>
            </div>

        </PageLayout>
    );
};

const Banner = ({windowWidth}) => {
    if (windowWidth > 480) {
        return (
            <>
                <h1 style={{textAlign: 'center', fontSize: '5vw', lineHeight: '1.5', fontWeight: '600'}}>
                     React Native App becoming <b style={redHighlight}>hard to manage</b> and <b style={redHighlight}>slow to run</b>?
                </h1>
                <h2 style={{textAlign: 'center'}}>Let's change it. Time to <b style={highlight}>streamline</b> and <b style={highlight}>accelerate</b> your React Native App development.</h2>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{fontSize: '9vw', textAlign: 'center', lineHeight: '1.3'}}>
                    React Native App becoming <b style={redHighlight}>hard to manage</b> and <b style={redHighlight}>slow to run</b>?
                </h1>
                <h2 style={{textAlign: 'center'}}>Let's change it. Time to <b style={highlight}>streamline</b> and <b style={highlight}>accelerate</b> your React Native App development.</h2>
            </>
        );
    }
};

export default IndexPage;
